import React from "react"

import Layout from 'components/Layout/Layout';
import SEO from 'components/seo';
import Projects from 'components/Projects/Projects';

const ProjectsPage = ({data}) => (
	<Layout>
		<SEO title="Projects" />
		<Projects data={data}/>
	</Layout>
)

export const query = graphql`
  {
		allContentfulProject(
			filter: { isActive: { eq: true }}
			sort: { fields: [sortOrder], order: ASC } 
		) {
			nodes {
				slug
				heading
				subHeading
				projectPageThumbnail {
					fluid(maxWidth: 535) {
            ...GatsbyContentfulFluid_tracedSVG
          }
				}
			}
		}
	}
`

export default ProjectsPage;
